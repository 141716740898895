import React from "react";
import { Link } from "gatsby";
import {Helmet} from "react-helmet";

import "../scss/main.scss";

const Error404 = () => {
   return (
      <>
         <Helmet>
         <meta charset="utf-8"/>
         <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
         <meta name="viewport" content="width=device-width,initial-scale=1"/>
         <script defer data-domain="electbitcoin.org" src="https://data.electbitcoin.org/js/script.js"></script>
         <title>ElectBitcoin.org - Error 404</title>
         </Helmet>
         <div className="authincation h-100 p-meddle">
            <div className="container h-100">
               <div className="row justify-content-center h-100 align-items-center ">
                  <div className="col-md-5">
                     <div className="form-input-content text-center error-page">
                        <h1 className="error-text font-weight-bold">404</h1>
                        <h4>
                           <i className="fa fa-exclamation-triangle text-warning" />{" "}
                           The page you were looking for was not found!
                        </h4>
                        <p>
                           You may have mistyped the address or the page may have
                           moved.
                        </p>
                        <div>
                           <Link className="btn btn-primary rounded" to="/">
                              Back to Home
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Error404;